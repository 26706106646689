import { ROLES } from '../../constants';
import model from './model';
import { sdk } from '@wix/image-kit';

const TARGET_SIZE = 1000;

export default model.createController(({ $props, $widget }) => ({
  pageReady: async ($w) =>
    $widget.onPropsChanged(() => {
      const category = $props.category;
      const imageSelector = `#${ROLES.image}`;
      const titleSelector = `#${ROLES.title}`;
      const descriptionSelector = `#${ROLES.description}`;

      const restoreIfDeleted = (selector) => {
        if ($w(selector).deleted) {
          $w(selector).restore();
        }
      };

      if (category.label) {
        restoreIfDeleted(titleSelector);
        $w(titleSelector).text = category.label;
      } else {
        $w(titleSelector).delete();
      }

      if (category.cover) {
        restoreIfDeleted(imageSelector);
        $w(imageSelector).src = sdk.getScaleToFitImageURL(
          category.cover.id,
          category.cover.width,
          category.cover.height,
          TARGET_SIZE,
          TARGET_SIZE,
          { quality: 100, preferredExtension: 'webp' },
        );
      } else {
        $w(imageSelector).delete();
      }

      if (category.description) {
        restoreIfDeleted(descriptionSelector);
        $w(descriptionSelector).text = category.description;
      } else {
        $w(descriptionSelector).delete();
      }
    }),
  exports: {},
}));
